import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Item = memo(function Item({ description, image, title }: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      <LazyLoadComponent>
        <StyledImage {...image} />
      </LazyLoadComponent>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 2.5rem);
  margin-left: 2.5rem;
  margin-bottom: 6.25rem;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 4.25rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-height: 31.75rem;
  aspect-ratio: 600/400;

  @media (max-width: 992px) {
    max-width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Title = styled.h3`
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.1875rem;
  letter-spacing: 0.02em;
  line-height: 3.0625rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5625rem;
  }
`

const Description = styled.div`
  margin-top: 1.125rem;
  padding-right: 3.75vw;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`
