import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Accordion as ReactAccordion } from 'react-accessible-accordion'

import { Item } from './Item'

export interface Props {
  items?: string[]
}

export const Accordion = memo(function Accordion({ items }: Props) {
  return (
    <Container>
      <Wrapper>
        {items ? (
          <Items>
            <ReactAccordion allowZeroExpanded>
              {items.map((item: any, index) => (
                <Item key={index} {...item} />
              ))}
            </ReactAccordion>
          </Items>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 5rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  max-width: 97.75rem;
  margin: 0 auto;
`

const Items = styled.div`
  .accordion {
    > div {
      margin-top: 1.25rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight2};
      transition: 0.5s ease-in-out;

      svg {
        fill: ${({ theme }) => theme.colors.variants.primaryDark};
      }

      &.active {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
`
