import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'

export interface Props {
  description?: string
  googleMapsURL?: string
  label?: string
  languageCode: string
  longitude?: string
  latitude?: string
  title?: string
}

export const Directions = memo(function Directions({
  description,
  googleMapsURL,
  label,
  languageCode,
  longitude,
  latitude,
  title,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const bounds = [] as any

  bounds.push([latitude, longitude])

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [75, 101],
        iconAnchor: [37.5, 101],
        html: `<img src="/map_marker.png" alt="Duodo Palace Hotel" width="75" height="101">`,
        className: 'main-icon',
      }),
    )
  }, [])

  const attractions = [
    {
      title: 'Piazza San Marco',
      latitude: 45.4338423070483,
      longitude: 12.338076196343804,
      icon: L.divIcon({
        iconSize: [40, 50],
        iconAnchor: [20, 50],
        html: `<img src="/piazza-san-marco.png" alt="Piazza San Marco" width="50" height="50">`,
        className: 'attraction-icon',
      }),
    },
    {
      title: 'Ponte di Rialto',
      latitude: 45.4379992275122,
      longitude: 12.335887269104711,
      icon: L.divIcon({
        iconSize: [40, 50],
        iconAnchor: [20, 50],
        html: `<img src="/ponte-rialto.png" alt="Ponte di Rialto" width="50" height="50">`,
        className: 'attraction-icon',
      }),
    },
    {
      title: 'Canal Grande',
      latitude: 45.43535829028392,
      longitude: 12.327884847375264,
      icon: L.divIcon({
        iconSize: [40, 50],
        iconAnchor: [20, 50],
        html: `<img src="/canal-grande.png" alt="Canal Grande" width="50" height="50">`,
        className: 'attraction-icon',
      }),
    },
    {
      title: 'Teatro la Fenice',
      latitude: 45.43360645516585,
      longitude: 12.333755705090985,
      icon: L.divIcon({
        iconSize: [40, 50],
        iconAnchor: [20, 50],
        html: `<img src="/teatro-la-fenice.png" alt="Teatro La Fenice" width="50" height="50">`,
        className: 'attraction-icon',
      }),
    },
    {
      title: 'Ponte dei Sospiri',
      latitude: 45.434272157303106,
      longitude: 12.34085439794053,
      icon: L.divIcon({
        iconSize: [40, 50],
        iconAnchor: [20, 50],
        html: `<img src="/ponte-sospiri.png" alt="Ponte dei Sospiri" width="50" height="50">`,
        className: 'attraction-icon',
      }),
    },
    {
      title: "Ponte dell'Accademia",
      latitude: 45.431703074495374,
      longitude: 12.328897069353511,
      icon: L.divIcon({
        iconSize: [40, 50],
        iconAnchor: [20, 50],
        html: `<img src="/ponte-accademia.png" alt="Ponte dell'Accademia" width="50" height="50">`,
        className: 'attraction-icon',
      }),
    },
  ]

  return (
    <Container>
      <Wrapper>
        <Inner>
          <Left>
            {label ? (
              <FadeInUp>
                <Label>{label}</Label>
              </FadeInUp>
            ) : null}
            {title ? (
              <FadeInUp>
                <Title>{title}</Title>
              </FadeInUp>
            ) : null}
            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
            {googleMapsURL ? (
              <FadeInUp>
                <StyledButton
                  label={useVocabularyData('our-location', languageCode)}
                  URL={googleMapsURL}
                  rel="noreferrer"
                  target="_blank"
                />
              </FadeInUp>
            ) : null}
          </Left>

          {typeof window !== undefined && latitude && longitude ? (
            <Map
              center={[Number(latitude), Number(longitude)]}
              dragging={L.Browser && L.Browser.mobile ? false : true}
              zoom={13}
              scrollWheelZoom={false}
              whenCreated={(mapInstance: any) => {
                map.current = mapInstance
                mapInstance.fitBounds(bounds)
                mapInstance.setZoom(16)
              }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                //url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              />
              <Marker
                icon={markerIcon}
                position={[Number(latitude), Number(longitude)]}
              />
              {attractions.map((item, index) => (
                <Marker
                  key={index}
                  icon={item.icon}
                  position={[Number(item.latitude), Number(item.longitude)]}
                >
                  <Tooltip offset={[0, 30]} permanent={true}>
                    {item.title}
                  </Tooltip>
                </Marker>
              ))}
            </Map>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 9.375rem;

  @media (max-width: 1199px) {
    margin-top: 5rem;
  }
`

const Wrapper = styled.div`
  margin-left: 11.125vw;

  @media (max-width: 1199px) {
    margin-left: 0;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1199px) {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 35%;
  max-width: 29.375rem;
  margin-right: 8.75vw;

  @media (min-width: 1920px) {
    margin-right: 5.813vw;
  }

  @media (max-width: 1199px) {
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    padding: 0 1.5625rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  line-height: 1.6875rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  max-width: 43.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Description = styled.div`
  margin-top: 1.9375rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    max-width: 39.375rem;
    margin-top: 1.75rem;
  }
`

const StyledButton = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Map = styled(MapContainer)`
  width: 65%;
  height: 67.777vh;
  max-height: 38.125rem;

  @media (min-width: 1920px) {
    width: calc(65% - 5.813vw);
    max-height: 43rem;
  }

  @media (max-width: 1199px) {
    width: 100%;
    max-height: 34.375rem;
    margin-top: 4.25rem;
  }

  @media (max-width: 768px) {
    max-height: 25rem;
  }

  .leaflet-marker-pane img {
    @media (max-width: 768px) {
      transform: scale(0.7);
    }
  }

  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 1 !important;

    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;

      a {
        position: relative;
        width: 3.4375rem;
        height: 3.4375rem;
        background: ${({ theme }) => theme.colors.variants.primaryLight};
        border: 0;
        border-radius: 0% !important;
        line-height: 2.875rem;
        text-indent: -9999px;
        transition: 0.2s ease-out;

        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.primaryDark};

            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.primaryLight};
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            opacity: 0.2;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }

  .leaflet-tooltip {
    text-align: center !important;
    width: 8.75rem !important;
    margin-left: -4.0625rem !important;
    height: auto !important;
    margin-top: -0.625rem;
    font-size: 0.75rem;

    &::before {
      display: none !important;
    }
  }
  .leaflet-tooltip-left {
    margin-left: 4.6875rem !important;
  }
  .leaflet-marker-icon {
    width: 50px !important;
  }
`
