import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const BlocksList = memo(function BlocksList({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Items>
          {items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </Items>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 5rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  max-width: 97.75rem;
  margin: 0 auto;
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -2.5rem;
  margin-bottom: -6.25rem;

  @media (max-width: 992px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: -4.25rem;
  }
`
