import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

import { Icon } from './Icon'

export interface Props {
  description?: string
  isExpanded?: boolean
  label?: string
  icon?: string
}

export const Item = memo(function Item({ description, label, icon }: Props) {
  if (!label) {
    return null
  }

  return (
    <Container activeClassName="active">
      <Head>
        <Title>
          {icon ? <Icon icon={icon} /> : null}
          {label}
          <Arrow
            src="/icon_arrow_accordion.svg"
            alt="expand"
            className="arrow"
            width="28"
            height="28"
          />
        </Title>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)``

const Head = styled(AccordionItemHeading)`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2.0625rem 3.75rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-weight: bold;
  font-size: 2.1875rem;
  letter-spacing: 0.02em;
  line-height: 2.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  @media (max-width: 768px) {
    padding: 2.0625rem;
    font-size: 1.5625rem;
  }
`

const Arrow = styled.img`
  margin-left: auto;
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  padding: 0 3.75rem;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 0 2.0625rem;
  }
`

const Description = styled.div`
  padding-bottom: 3.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;

  ul {
    list-style: inside;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    padding-bottom: 2.0625rem;
  }
`
